<template>
    <div
        v-if="!isMainPage"
        class="template-navigation"
    >
        <div
            :class="{
                'template-navigation-icon-mobile': true,
                'close' : mobileMenuOpen
            }"
            @click="handleMobileMenuChange"
        />
        <div
            :class="{
                'template-navigation-link': true,
                'template-navigation-link-full' : mobileMenuOpen
            }"
        >
            <router-link
                to="/supplier/personal/#form_pay"
            >
                Заявки
            </router-link>
            <router-link
                to="/supplier/personal/#form_pay"
            >
                Баланс
            </router-link>
            <router-link
                to="/supplier/personal/#fag"
            >
                FAQ
            </router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {useGlobalStore} from "@/stores/global";
export default defineComponent({
  setup () {
    const globalStore = useGlobalStore();
    return {
      globalStore
    };
  },
  data() {
    return {
      mobileMenuOpen: false
    }
  },
  computed: {
    isMainPage() {
      return !this.$route.path.replaceAll('/', '');
    }
  },
  async mounted() {
    await new Promise(res => setTimeout(res, 100));
    const method = this.$route.hash.replace(/#|\\|.| /, '').replace(/(^.|_.)/g, a => { return (a.length === 1 ? a : a[1]).toUpperCase() });

    // eslint-disable-next-line no-prototype-builtins
    if(method && this.globalStore.hasOwnProperty(`show${method}`)) {
      console.log(`show${method}`)
      this.globalStore[`show${method}`]()
    }
  },
  methods: {
    handleMobileMenuChange() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      document.body.style.overflow = this.mobileMenuOpen ? 'hidden' : 'visible';
    }
  }
});
</script>

<style lang="less">
.template-navigation {
  width: calc(100% - 280px);
  max-width: 670px;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  justify-content: end;
  align-items: center;

  .template-navigation-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;

    a {
      display: flex;
      color: rgba(214, 213, 214, 1);
      margin: 0 10px;
      text-decoration: none;
    }

    .template-navigation-login {
      color: rgba(232, 129, 2, 1);
    }
  }

  .template-navigation-link-full {
    background-color: #38363B;
    display: flex !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    margin: 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    a {
      margin: 20px 10px;
    }
  }

  .template-navigation-icon-mobile {
    position: relative;
    z-index: 2;
    display: none;
    width: 50px;
    height: 50px;
    background: url(@/assets/icon_mobule_menu.svg) center no-repeat;
    cursor: pointer;

    &.close {
      background: url(@/assets/icon_mobile_menu_close.svg) center no-repeat;
    }
  }
}
</style>
