<template>
    <div

        id="fag"
        class="f-a-g-wrap"
    >
        <div
            class="f-a-g-content"
        >
            <div class="f-a-g-title">
                FAQ
            </div>

            <el-collapse accordion>
                <el-collapse-item
                    title="Что следует проверить?"
                    name="1"
                >
                    <ul>
                        <li>Указан логин аккаунта Steam, не никнейм.</li>
                        <li>
                            Страна аккаунта Steam может быть любой (не только Россия). Валюта пополнения USD будет конвертирована в валюту аккаунта.
                            Если в аккаунте указаны рубли, соответственно, пополнение ожидайте в рублях.
                        </li>
                        <li>Есть ли у вас на аккаунте красная табличка (КТ)? Советуем проверить до пополнения, средства вернуть при успешном пополнении невозможно.</li>
                        <li>
                            Ограничений по стране аккаунта Steam нет. Для некоторых стран аккаунта пополнение может быть недоступно (сервис даст актуальную информацию.
                            Деньги при этом списаны не будут.
                        </li>
                    </ul>
                </el-collapse-item>
                <el-collapse-item
                    title="Что такое логин Steam и где его посмотреть?"
                    name="2"
                >
                    Логин аккаунта Steam - используется для авторизации в аккаунт Steam.
                    Еще его можно посмотреть, нажав на свой ник на платформе Steam и перейти на страницу «Об аккаунте».
                </el-collapse-item>
                <el-collapse-item
                    title="Какие способы пополнения Steam из России вы поддерживаете?"
                    name="3"
                >
                    Банковские карты Мир, Visa и Mastercard, выпущенные любым российским банком. Зарубежные карты любых банков.
                </el-collapse-item>
                <el-collapse-item
                    title="Не приходят деньги на кошелек стим. Что делать?"
                    name="4"
                >
                    Пополнение Steam через все эти платежные методы происходит в течение 15 минут.
                    Если средства на кошелек стим не поступили в течение этого времени, пожалуйста, обратитесь в службу нашей поддержки с вашим email, логином и суммой
                    (так мы быстрее проверим статус платежа и дадим ответ).
                </el-collapse-item>
                <el-collapse-item
                    title="Не совпадает сумма пополнения баланса стим. Что делать?"
                    name="5"
                >
                    При пополнении Steam происходит конвертация средства в разных валютах и иногда сумма может отличаться на 1-5% от указанной при самой оплате.
                    Невозможно предусмотреть незначительные изменения во всех случаях.
                </el-collapse-item>
                <el-collapse-item
                    title="Как долго приходят деньги на кошелек Steam. Как проверить?"
                    name="6"
                >
                    Если вы соблюдали все требования для пополнения (указали корректный логин аккаунта), то пополнение Steam поступает на счет в течение 15 минут.
                    Если этого не произошло, пожалуйста, обратитесь в службу поддержки с указанием вашего email.
                </el-collapse-item>
                <el-collapse-item
                    title="Указали неверный логин аккаунта Steam"
                    name="7"
                >
                    Если вы указали некорректный логин аккаунта или указали никнейм вместо логина и он существует в Steam,
                    то деньги уйдут другому пользователю и вернуть их невозможно. Пожалуйста, будьте внимательны при создании заявки.
                </el-collapse-item>
                <el-collapse-item
                    title="Как пополнить новый аккаунт Steam в России, чтобы не потерять регион?"
                    name="8"
                >
                    При создании нового аккаунта Steam укажите страну Россия и добавьте 2 бесплатных игры в библиотеку Steam. После этого пополняйте баланс своего аккаунта.
                    Обращаем внимание, что для некоторых регионов действуют ограничения. Учитывайте при выборе ГЕО под свои интересы.
                    Более подробную и точную информацию следует смотреть на форумах или в инфо самого сайта Steam.
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      account: '',
      amount: 0,
      email: ''
    }
  }
});
</script>

<style lang="less">

.f-a-g-wrap {
  display: flex;
  min-height: 100vh;
  align-items: center;
  width: 100%;
  max-width: 1080px;

  ul {
    margin: 0;
    padding: 0 0 0 18px;
    max-width: 100%;
    list-style-type: disc;
  }
}

.f-a-g-content {
  width: 100%;
  margin: 0 20px 20px;
  display: flex;
  flex-direction: column;
}

.f-a-g-title {
  font-size: 24px;
  margin-bottom: 28px;
}

.el-collapse {
  border: none;
}

.el-collapse-item {
  width: 100%;
  margin: 20px 0;
  border-radius: 16px;
  overflow: hidden;
}

.el-collapse-item__header {
  height: auto;
  font-weight: 700;
  padding: 24px;
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  background-color: #38363BFF;
  border: none;
  text-align: left;
}

.el-collapse-item__wrap {
  padding: 0 48px;
  background-color: #38363BFF;
  border: none;
}

.el-collapse-item__arrow {
  margin: 0 0 0 auto;
  padding-left: 12px;
}

.el-collapse-item__content {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 24px;
}

@media (max-width: 1100px) {
  .f-a-g-wrap {
    min-height: auto;
  }

  .f-a-g-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
  }

  .el-collapse-item__header {
    padding: 20px;
    font-size: 16px;
    line-height: 20px;
  }

  .el-collapse-item__content {
    font-size: 14px;
    line-height: 22px;
  }

}

</style>
