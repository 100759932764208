import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "template-navigation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.isMainPage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass({
                'template-navigation-icon-mobile': true,
                'close' : _ctx.mobileMenuOpen
            }),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMobileMenuChange && _ctx.handleMobileMenuChange(...args)))
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass({
                'template-navigation-link': true,
                'template-navigation-link-full' : _ctx.mobileMenuOpen
            })
        }, [
          _createVNode(_component_router_link, { to: "/supplier/personal/#form_pay" }, {
            default: _withCtx(() => [
              _createTextVNode(" Заявки ")
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/supplier/personal/#form_pay" }, {
            default: _withCtx(() => [
              _createTextVNode(" Баланс ")
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/supplier/personal/#fag" }, {
            default: _withCtx(() => [
              _createTextVNode(" FAQ ")
            ]),
            _: 1
          })
        ], 2)
      ]))
    : _createCommentVNode("", true)
}