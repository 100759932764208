<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <div class="form-text-status-title">
                Проверьте почту
            </div>
            <div class="form-text-status-description">
                Мы отправили Вам письмо с ссылкой для восстановления пароля
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {useClientStore} from "@/stores/client";
import {useGlobalStore} from "@/stores/global";
import dayjs from "dayjs";

export default defineComponent({
  setup () {
    const globalStore = useGlobalStore();
    return {
      showFormPay: globalStore.showFormPay
    };
  }
});
</script>
