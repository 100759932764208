<template>
    <div

        class="template-table"
    >
        <div

            class="template-table-wrap"
        >
            <table>
                <thead>
                    <tr>
                        <th>Сумма, ₸</th>
                        <th>Доход, ₸</th>
                        <th>Оставшиеся время</th>
                        <th>&#x20;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>2849.90</td>
                        <td class="price">
                            20.00
                        </td>
                        <td>45:00</td>
                        <td><button>Оплатить</button></td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>2149.90</td>
                        <td class="price">
                            10.00
                        </td>
                        <td>5:00</td>
                        <td><button>Оплатить</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useGlobalStore } from "@/stores/global";
export default defineComponent({
  setup () {
    const globalStore = useGlobalStore();
    return {
      globalStore
    };
  },
  data() {
    return {
      mobileMenuOpen: false
    }
  },
  computed: {
    isMainPage() {
      return !this.$route.path.replaceAll('/', '');
    }
  },
  async mounted() {
    await new Promise(res => setTimeout(res, 100));
    const method = this.$route.hash.replace(/#|\\|.| /, '').replace(/(^.|_.)/g, a => { return (a.length === 1 ? a : a[1]).toUpperCase() });

    // eslint-disable-next-line no-prototype-builtins
    if(method && this.globalStore.hasOwnProperty(`show${method}`)) {
      console.log(`show${method}`)
      this.globalStore[`show${method}`]()
    }
  },
  methods: {
    handleRouterChange() {

    }
  }
});
</script>

<style lang="less">

.template-table {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding-top: 100px;

  .template-table-wrap {
    background: #38363B;
    border-radius: 16px;
    padding: 20px;
    min-width: 700px;
  }

  table {
    width: 100%;
    padding: 0;
    margin: 0;
    border-spacing: 0;
    border-collapse: separate;

    th {
      padding: 16px;
      font-size: 12px;
      text-align: left;
    }

    td {
      text-align: left;
      border-top: 2px solid #4C4A4F;
      padding: 16px;
      font-size: 24px;

      &.price {
        color: #E88102;
      }

      &:last-child {
        width: 100px;
        text-align: right;
      }

      button {
        background: #E88102;
        padding: 4px 12px;
        margin: 0;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
}


</style>
