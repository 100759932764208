import {defineStore} from "pinia";
import {postDataLogin, postDataRegistration, postDataRestoreEmail} from "@/api";
import {useGlobalStore} from "@/stores/global";
import {RequestLogin, RequestRegistration} from "@/api/types";

export const useSupplierStore = defineStore({
    id: "supplierStore",
    state: (): any =>
        ({
            authToken: ''
        }),
    actions: {
        async login(param: RequestLogin) {
            try {
                const {token, success, errorMessage} = await postDataLogin(param);
                if(success) {
                    this.authToken = token;
                }
                return {success, errorMessage};
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async registration(param: RequestRegistration) {
            try {
                const {token, success, errorMessage} = await postDataRegistration(param);
                if(success) {
                    this.authToken = token;
                }
                return {success, errorMessage};
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async restore(param: RequestRegistration) {
            try {
                const {token, success, errorMessage} = await postDataRestoreEmail(param);
                if(success) {
                    this.authToken = token;
                }
                return {success, errorMessage};
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        }
    }
});
