import {createPinia} from 'pinia';
import {createApp} from 'vue';
import {createRouter, createWebHistory} from 'vue-router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import App from './App.vue';
// @ts-ignore
import VueGtag from 'vue-gtag';

import MainPage from '@/views/MainPage.vue';
import SteamPage from '@/views/SteamPage.vue';
import SupplierAuthPage from '@/views/SupplierAuthPage.vue';
import SupplierPersonalPage from '@/views/SupplierPersonalPage.vue';
import TopUpStatusPage from '@/views/TopUpStatusPage.vue';
import EmailRestoreStatusPage from '@/views/EmailRestoreStatusPage.vue';
import RegistrationStatusPage from '@/views/RegistrationStatusPage.vue';
import {version} from '@/version';

const routes = [
    {path: '/', component: MainPage, meta: { template : 'TemplateMain' }},
    {path: '/steam', component: SteamPage, meta: { template : 'TemplateSteam' }},
    {path: '/topup/status/:id', component: TopUpStatusPage, meta: { template : 'TemplateSteam' }},
    {path: '/supplier', component: SupplierPersonalPage, meta: { template : 'TemplateSupplier' }},
    {path: '/supplier/auth', component: SupplierAuthPage, meta: { template : 'TemplateMain' }},
    {path: '/supplier/auth/recover/:id', component: EmailRestoreStatusPage, meta: { template : 'TemplateSteam' }},
    {path: '/supplier/auth/registration', component: RegistrationStatusPage, meta: { template : 'TemplateSteam' }}
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            };
        }
    }
});

console.log(`Version: ${version}`);

createApp(App)
    .use(ElementPlus)
    .use(createPinia())
    .use(router)
    .use(VueGtag, {
        config: {id: 'G-X4W5CLQH90'}
    })
    .mount('#app');
