<template>
    <div class="home-content">
        <popup-error v-if="globalStore.showPopup.errorRequest" />
        <popup-success-restore-email v-if="globalStore.showPopup.successRestoreEmail" />
        <popup-success-registration v-if="globalStore.showPopup.successRegistration" />
        <form-login v-else-if="globalStore.showForm.login" />
        <form-registration v-else-if="globalStore.showForm.registration" />
        <form-restore-email v-else-if="globalStore.showForm.restoreEmail" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useGlobalStore } from "@/stores/global";

import PopupError from "@/components/popup/PopupError.vue";
import PopupSuccessRestoreEmail from "@/components/popup/PopupSuccessRestoreEmail.vue";
import PopupSuccessRegistration from "@/components/popup/PopupSuccessRegistration.vue";
import FormLogin from "@/components/form/FormLogin.vue";
import FormRegistration from "@/components/form/FormRegistration.vue";
import FormRestoreEmail from "@/components/form/FormRestoreEmail.vue";

export default defineComponent({
  components: {
    PopupSuccessRestoreEmail,
    PopupSuccessRegistration,
    PopupError,
    FormLogin,
    FormRegistration,
    FormRestoreEmail
  },
  setup () {
    const globalStore = useGlobalStore();
    return {
      globalStore
    };
  }
});
</script>
