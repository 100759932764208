<template>
    <div
        id="form_registration"
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Регистрация исполнителя (Казахстан)
            </h2>
            <div class="form-error-message">
                {{ errorMessage }}
            </div>

            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Email
                    </div>
                    <input
                        v-model="email"
                        :class="{'shake' : emailAnimation}"
                        type="text"
                        placeholder="example@email.com"
                    >
                </div>
            </div>

            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Пароль
                    </div>
                    <input
                        v-model="password"
                        :class="{'shake' : passwordAnimation}"
                        type="password"
                        placeholder="qwerty12"
                    >
                </div>
                <div class="form-field-help">
                    <div class="form-field-help-title">
                        Введите пароль
                    </div>
                    8 и более символов и как минимум:
                    <ul>
                        <li>одна заглавная и одна строчная буква</li>
                        <li>одна цифра</li>
                        <li>один специальный символ, например: ~!?@#$%^&*</li>
                    </ul>
                </div>
                <div class="form-field-icon icon-help" />
            </div>

            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Подтверждение пароля
                    </div>
                    <input
                        v-model="confirmPassword"
                        :class="{'shake' : confirmPasswordAnimation}"
                        type="password"
                        placeholder="qwerty12"
                    >
                </div>
                <div class="form-field-help">
                    <div class="form-field-help-title">
                        Введите пароль
                    </div>
                    8 и более символов и как минимум:
                    <ul>
                        <li>одна заглавная и одна строчная буква</li>
                        <li>одна цифра</li>
                        <li>один специальный символ, например: ~!?@#$%^&*</li>
                    </ul>
                </div>
                <div class="form-field-icon icon-help" />
            </div>

            <div class="form-field-agreement">
                <input
                    id="checkbox_agreement"
                    v-model="agreement"
                    :class="{'bounce' : agreementAnimation}"
                    type="checkbox"
                >
                <label
                    for="checkbox_agreement"
                    class="form-field-label"
                >
                    Я согласен с
                    <a
                        target="_blank"
                        href="@/download/Публичная_оферта_для_плательщика_1_1.pdf"
                    >
                        условиями сервиса
                    </a>
                    и подтверждаю корректность данных
                </label>
            </div>

            <vue-recaptcha
                ref="recaptcha"
                class="form-captcha"
                :class="{'shake' : captchaAnimation}"
                sitekey="6LdcM9opAAAAADbrHF-YFhrH1qZBbhMbbGbuFP5l"
                theme="dark"
                @verify="handleSuccessCaptcha"
            />

            <button
                class="form-button-submit"
                @click="handleSuccess"
            >
                Зарегистрироваться
            </button>

            <div class="form-text-why-no-registration">
                Есть аккаунт?
                <router-link
                    to="/supplier/#form_login"
                    @click="globalStore.showFormLogin"
                >
                    Войти
                </router-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {useGlobalStore} from "@/stores/global";
import {useSupplierStore} from "@/stores/supplier";
import { VueRecaptcha } from 'vue-recaptcha';

export default defineComponent({
  components: { VueRecaptcha },
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();
    const recaptcha = ref<VueRecaptcha | null>(null)
    return {
      recaptcha,
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      errorMessage: '',
      passwordAnimation: false,
      confirmPasswordAnimation: false,
      emailAnimation: false,
      agreementAnimation: false,
      captchaAnimation: false,
      password: '',
      confirmPassword: '',
      email: '',
      agreement: false,
      greCaptchaResponse: ''
    }
  },
  methods: {
    async handleSuccessCaptcha(greCaptchaResponse: string) {
      this.greCaptchaResponse = greCaptchaResponse;
    },
    async handleSuccess() {
      this.passwordAnimation = false;
      this.emailAnimation = false;
      this.confirmPasswordAnimation = false;
      this.agreementAnimation = false;
      this.captchaAnimation = false;
      this.errorMessage = '';

      await new Promise(res => setTimeout(res, 100));

      const {password, email, greCaptchaResponse, confirmPassword} = this;

      if(!/^\S+@\S+\.\S+$/.test(email)) {
        this.emailAnimation = true;
      } else if(!/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/.test(password)) {
        this.passwordAnimation = true;
        this.errorMessage = 'Придумайте другой пароль';
      } else if(password !== confirmPassword) {
        this.confirmPasswordAnimation = true;
        this.errorMessage = 'Пароли не совпадают';
      } else if(!this.agreement) {
        this.agreementAnimation = true;
      } else if(this.greCaptchaResponse === '') {
        this.captchaAnimation = true;
      } else {
        const {success, errorMessage} = await this.supplierStore.registration({password, email, greCaptchaResponse})

        if(success) {
          this.password = '';
          this.confirmPassword = '';
          this.email = '';
          this.errorMessage = '';
          this.greCaptchaResponse = '';
          this.agreement = false;
          if(this.recaptcha) {
            this.recaptcha.reset()
          }
          this.globalStore.showSuccessRegistrationPopup()
        } else {
          this.errorMessage = errorMessage;
        }
      }
    }
  }
});
</script>

<style lang="less">

</style>
