<template>
    <component :is="currentTemplate">
        <router-view />
    </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TemplateSteam from "@/views/template/TemplateSteam.vue";
import TemplateSupplier from "@/views/template/TemplateSupplier.vue";
import TemplateMain from "@/views/template/TemplateMain.vue";

export default defineComponent({
  components: {
    TemplateSteam,
    TemplateSupplier,
    TemplateMain
  },
  computed:{
    currentTemplate(){
      return this.$route.meta.template
    }
  }
});
</script>

<style lang="less">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html, body, #app {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
}
html, #app {
  //height: 100vh;
}
body {
  font-family: "Inter", sans-serif;
  min-height: 100%;
}

button {
  font-family: "Inter", sans-serif;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: #ACACAC;
}
input[type='checkbox'],
input[type='radio'] {
  --active: #E88102;
  --border: #E88102;
  --background: #E88102;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 20px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  width: 22px;

  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    opacity: var(--o, 0);
  }
  &:checked {
    --o: 1;
    --b: var(--active);
    --bc: var(--active);
  }
  &:focus {
    --bc: var(--active);
  }
}
input[type='checkbox'] {
  border-radius: 4px;
    &:after {
      width: 5px;
      height: 9px;
      border: 2px solid #ffffff;
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 4px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
}
input[type='radio'] {
  border-radius: 50%;
  &:after {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0;
    transform: scale(var(--s, .7));
  }
  &:checked {
    --s: .5;
  }
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #535353;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #414040;
  }
}

ul {
  margin: 12px;
  padding: 0;
  //list-style: none;
  width: 100%;
  max-width: 320px;
  li {
    margin: 16px 0;
    position: relative;
  }
}

html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

.form-wrap {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding-top: 100px;
}

.form-content {
  position: relative;
  width: 550px;
  display: flex;
  padding: 24px;
  text-align: left;
  flex-direction: column;
  background-color: rgba(56, 54, 59, 1);
  border-radius: 16px;
  box-sizing: border-box;

  h2 {
    margin: 0 0 20px;
    font-size: 24px;
    line-height: 28px;
  }

  h3 {
    margin: -12px 0 20px;
    font-size: 16px;
    line-height: 19px;
    color: #D6D5D6;
    font-weight: 400;
    //letter-spacing: -1px;
  }
}

.form-error-message {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #f32f2f;
  margin-top: -16px;
  height: 16px;
}

.form-field-line-amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .form-field {
    width: 100%;
    margin-right: 12px;

    &:last-child {
      width: 112px;
      margin-right: 0;
    }
  }
}

.form-field-line-amount-add {
  display: flex;
  flex-direction: row;

  .form-field {
    margin-right: 12px;
    padding: 0;

    &:last-child {
      margin-right: 0;
    }

    button {
      margin: 0;
      padding: 8px 12px;
      border: 0;
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      background-color: transparent;
      color: #ACACAC;
      cursor: pointer;
    }
  }
}

.form-field {
  border-radius: 6px;
  border: 1px solid #535353;
  padding: 10px 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;

  &:focus, &:hover {
    border-color: #AFAFB1;

    .form-field-help {
      display: block;
    }
  }

  input, textarea {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 20px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    background-color: transparent;
    color: #ffffff;
  }

  textarea {
    height: 80px;
    resize: vertical;
  }
}

.form-field-label {
  cursor: text;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #ACACAC;
  padding-bottom: 8px;
}

.form-field-content {
  width: 100%;
}

.form-field-help {
  display: none;
  width: 350px;
  left: 100%;
  position: absolute;
  border-radius: 12px;
  padding: 24px;
  background: #38363B99;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 24px;
  z-index: 3;
}

.form-field-help-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 21px;
}

.form-text-restore-password {
  text-align: right;
  margin: 12px 0 0;
}

.form-text-why-no-registration {
  margin-top: 24px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: #ACACAC;

  a {
    color: #E88102;
  }
}

.form-field-agreement {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    color: #E88102;
  }

  .form-field-label {
    font-size: 16px;
    line-height: 22px;
    color: #ACACAC;
    padding: 0;
    margin: 0 0 0 10px;
  }
}

.form-field-icon {
  margin-left: 8px;
  height: 24px;
  width: 24px;
}

.icon-help {
  background: center center url("@/assets/icon_help.svg") no-repeat;
}

.icon-steam {
  background: center center url("@/assets/icon_steam.svg") no-repeat;
}

.form-button-submit {
  cursor: pointer;
  margin-top: 24px;
  background-color: #E88102;
  border-radius: 8px;
  padding: 16px 24px;
  color: #EDEDED;
  font-size: 20px;
  line-height: 20px;
  border: none;

  a {
    color: #EDEDED;
    display: block;
  }

  &:hover {
    .form-field-help {
      text-align: left;
      display: block;
      margin-top: -20px;
    }
  }
}

.form-text-status-title {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-top: 20px;
}

.form-text-status-description {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 15px auto;
}

.bounce {
  animation: bounce 2s ease 1;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-10px);}
  60% {transform: translateY(-5px);}
}

.shake {
  animation: shake 2s ease 1;
}

@keyframes shake {
  0%, 100% {transform: translateX(0);}
  10%, 30%, 50%, 70%, 90% {transform: translateX(-5px);}
  20%, 40%, 60%, 80% {transform: translateX(5px);}
}

.icon-loader {
  animation: rotate-loader 2s ease infinite;
}

.icon-loader {
  background: #E88102;
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;
  animation: 6s rotate linear infinite;
}
.icon-loader:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #BEBEBE;
  animation: 4s rotate reverse linear infinite;
}
.icon-loader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #FFFF;
  animation: 2s rotate reverse linear infinite;
}


@keyframes rotate {
  0%{transform: rotate(0deg)}
  100%{transform: rotate(360deg)}
}




@media (max-width: 1100px) {
  input[type='checkbox'], input[type='radio'] {
    width: 27px;
    height: 24px;
  }

  .form-content {
    padding: 18px;
    margin: 0 20px;
    width: 100%;

    h2 {
      margin: 0 0 20px;
      font-size: 20px;
      line-height: 24px;
    }

    h3 {
      margin: -12px 0 20px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .form-field {
    &:focus, &:hover {
    }

    input, textarea {
      height: 18px;
      font-size: 16px;
      line-height: 16px;
    }

    textarea {

    }
  }

  .form-field-label {
    font-size: 12px;
    line-height: 12px;
    padding-bottom: 6px;
  }

  .form-wrap {
    min-height: auto;
    width: 100%;
    justify-content: center;
    padding-top: 66px;
  }

  .form-button-submit {
    font-size: 16px;
    line-height: 16px;
  }

  .form-field-help {
    width: 100%;
    left: -20px;
    top: -70px;
    padding: 16px;
    font-size: 14px;
    line-height: 19px;
    margin: 20px;
    background: #676767;
  }

  .form-field-help-title {
    font-size: 16px;
    margin-bottom: 6px;
    line-height: 19px;
  }

  .form-field-agreement {
    margin-top: 24px;

    .form-field-label {
      font-size: 14px;
      line-height: 18px;
    }
  }
}



</style>
