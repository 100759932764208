<template>
    <div
        id="form_pay"
        class="form-wrap"
    >
        <div

            class="form-content"
        >
            <h2>
                Пополнить Steam
            </h2>
            <div class="form-error-message">
                {{ !store.checkData.success ? store.checkData.errorMessage : '' }}
            </div>
            <div
                :class="{
                    'form-field': true,
                    'shake' : accountAnimation
                }"
            >
                <div class="form-field-content">
                    <label
                        class="form-field-label"
                        for="login_pay"
                    >
                        Логин Steam
                    </label>
                    <input
                        id="login_pay"
                        v-model="steamAccount"
                        type="text"
                        placeholder="quest"
                    >
                </div>
                <div class="form-field-help">
                    <div class="form-field-help-title">
                        Введите логин Steam
                    </div>
                    Если указан аккаунт другого человека вернуть деньги уже не получится
                </div>
                <div class="form-field-icon icon-steam" />
            </div>

            <div class="form-field-line-amount">
                <div
                    :class="{
                        'form-field': true,
                        'shake' : amountAnimation
                    }"
                >
                    <div class="form-field-content">
                        <label
                            for="email_amount"
                            class="form-field-label"
                        >
                            Сумма пополнения
                        </label>
                        <input
                            id="email_amount"
                            v-model="usdAmount"
                            type="text"
                            placeholder="100"
                        >
                    </div>
                    <div class="form-field-help">
                        <div class="form-field-help-title">
                            Введите сумму пополнения
                        </div>
                        Мин сумма - {{ store.session.usdAmountMin }}$ <br>
                        Макс сумма - {{ store.session.usdAmountMax }}$ <br>
                        Лимит пополнений за сутки - {{ store.session.usdTotalAMountDayMax }}$
                    </div>
                    <div class="form-field-icon icon-help" />
                </div>

                <div class="form-field">
                    <div class="form-field-content">
                        <label
                            class="form-field-label"
                        >
                            Валюта
                        </label>
                        <input
                            value="USD"
                            type="text"
                            disabled
                        >
                    </div>
                </div>
            </div>

            <div class="form-field-line-amount-add">
                <div class="form-field">
                    <button @click="addAmount(100)">
                        100$
                    </button>
                </div>
                <div class="form-field">
                    <button @click="addAmount(200)">
                        200$
                    </button>
                </div>
                <div class="form-field">
                    <button @click="addAmount(300)">
                        300$
                    </button>
                </div>
            </div>

            <div
                :class="{
                    'form-field': true,
                    'shake' : emailAnimation
                }"
            >
                <div class="form-field-content">
                    <label
                        for="email_pay"
                        class="form-field-label"
                    >
                        Email
                    </label>
                    <input
                        id="email_pay"
                        v-model="email"
                        type="text"
                        placeholder="example@email.com"
                    >
                </div>
                <div class="form-field-help">
                    <div class="form-field-help-title">
                        Проверьте email адрес
                    </div>
                    По введенному email сохраняется вся история пополнений. Служба поддержки сможет связаться с вами по этому адресу.
                </div>
                <div class="form-field-icon icon-help" />
            </div>

            <div class="form-field-agreement">
                <input
                    id="checkbox_agreement"
                    v-model="agreement"
                    :class="{'bounce' : agreementAnimation}"
                    type="checkbox"
                >
                <label
                    for="checkbox_agreement"
                    class="form-field-label"
                >
                    Я согласен с
                    <a
                        target="_blank"
                        href="../../download/Публичная_оферта_для_плательщика_1_1.pdf"
                    >
                        условиями сервиса
                    </a>
                    и подтверждаю корректность данных
                </label>
            </div>
            <button
                class="form-button-submit"
                @click="topUpCheckAndCreate"
            >
                {{ store.checkData.success ? `Перевести на ${store.checkData.rubAmount} сумму` : 'Подтвердить' }}
                <div
                    v-if="store.checkData.success"
                    class="form-field-help"
                >
                    <div class="form-field-help-title">
                        Подтвердите перевод
                    </div>
                    Максимальное время обработки заявки - {{ store.session.timeToTopup }} минут. Если деньги не придут за указанное время, обратитесь в техподдержку.
                </div>
            </button>
        </div>
    </div>
</template>

<script lang="ts">

import {useGlobalStore} from "@/stores/global";

type FormData = {
  steamAccount: string, // Логин для пополнения
  usdAmount: null | number, // Сумма пополнения в USD
  email: string, // Почта
  agreement: boolean, // Согласие с условиями сервиса
  agreementAnimation: boolean, // Анимация не заполнения согласия
  accountAnimation: boolean, // Анимация не заполнения логин стима
  amountAnimation: boolean, // Анимация не заполнения суммы
  emailAnimation: boolean, // Анимация не заполнения/корректности почты стима
}

import {defineComponent} from "vue";
import {useClientStore} from "@/stores/client";
export default defineComponent({
  setup () {
    const store = useClientStore();
    const globalStore = useGlobalStore();
    store.getStartSession();
    return {
      store,
      globalStore
    };
  },
  data(): FormData {
    return {
      agreementAnimation: false,
      accountAnimation: false,
      amountAnimation: false,
      emailAnimation: false,
      steamAccount: '',
      usdAmount: null,
      email: '',
      agreement: false
    }
  },
  watch: {
    agreement(val) {
      if(val) {
        this.agreementAnimation = false
      }
    },
    usdAmount() {
      this.store.checkData.success = false
    }
  },
  methods: {
    addAmount(amount: number) {
      this.usdAmount = amount;
    },
    async topUpCheckAndCreate() {
      this.agreementAnimation = false;
      this.accountAnimation = false;
      this.amountAnimation = false;
      this.emailAnimation = false;

      await new Promise(res => setTimeout(res, 100));
      if(!this.steamAccount) {
        this.accountAnimation = true;
      } else if(!this.usdAmount || this.usdAmount <= 0) {
        this.amountAnimation = true;
      } else if(!/^\S+@\S+\.\S+$/.test(this.email)) {
        this.emailAnimation = true;
      } else if(!this.agreement) {
        this.agreementAnimation = true;
      } else {
        this.agreement = false;
        this.globalStore.showPayCheck()
        await this.store.getStartSession()

        if(this.store.session.sessionId) {
          const {steamAccount, usdAmount, email} = this
          await this.store.topUpCheck({steamAccount, usdAmount, email})

          if(this.store.checkData.success) {
            this.globalStore.showFormPayFinish()
          } else {
            this.globalStore.showFormPay()
          }
        }
      }
    }
  }
});
</script>

<style lang="less">
.content-form-pay-wrap {
  margin: 20px 0;
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.form-pay-steam-wrap {
  width: 550px;
  display: flex;
  padding: 24px;
  text-align: left;
  flex-direction: column;
  background-color: rgba(56, 54, 59, 1);
  border-radius: 16px;
  box-sizing: border-box;

  h2 {
    font-size: 22px;
  }

  .form-pay-steam-field {
    display: flex;
    margin: 10px 0;
  }
}
</style>
