<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <img
                class="form-icon-success"
                src="@/assets/icon_popup_success.svg"
                alt=""
            >
            <div class="form-text-status-title">
                Вы успешно зарегистрированы!
            </div>
            <div class="form-text-status-description">
                Для выполнения заявок пройдите верификацию
            </div>
            <button class="form-button-submit">
                <router-link
                    to="/steam/#form_pay"
                    @click="showFormPay"
                >
                    Спасибо
                </router-link>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {useClientStore} from "@/stores/client";
import {useGlobalStore} from "@/stores/global";

export default defineComponent({
  setup() {
    const store = useClientStore();
    const globalStore = useGlobalStore();
    return {
      store,
      showFormPay: globalStore.showFormPay
    };
  }
});
</script>
