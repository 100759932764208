<template>
    <div
        class="main-wrap"
    >
        <div
            class="main-content"
        >
            <div class="main-content-title">
                Пополнить Steam 24/7
            </div>
            <div class="main-content-destruction">
                Пополняй баланс в USD аккаунта Steam по карте из России, СНГ и любой страны
            </div>

            <button
                class="form-button-submit"
            >
                <router-link
                    to="/steam"
                >
                    Пополнить
                </router-link>
            </button>

            <div class="main-content-info">
                ~ 1-15 минут до поступления денег
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({

});
</script>

<style lang="less">

.main-wrap {
  margin-top: -100px;
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.main-content {
  position: relative;
  display: flex;
  padding: 24px;
  text-align: center;
  flex-direction: column;
  box-sizing: border-box;

  .form-button-submit {
    width: 500px;
    margin: 0 auto 20px;
  }
}

.main-content-title {
  font-size: 64px;
  font-weight: 900;
  line-height: 78px;
  color: #FFFFFFCC;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.main-content-destruction {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  color: #D6D5D6;
  margin-bottom: 60px;
  letter-spacing: -1px;
}

.main-content-info {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #D6D5D6;
}

@media (max-width: 1100px) {
  .main-wrap {
    margin-top: -67px;
  }

  .main-content-title {
    font-size: 40px;
    line-height: 48px;
  }

  .main-content-destruction {
    font-size: 16px;
    line-height: 19px;
  }

  .main-content-info {
    font-size: 12px;
    line-height: 17px;
  }
}
</style>
