<template>
    <div
        :class="{
            'template-bg': true,
            'template-main-bg' : isMainPage
        }"
    >
        <div class="template-wrap">
            <div class="template-header-wrap">
                <div class="template-header-logo">
                    <router-link
                        v-if="isMainPage"
                        to="'/'"
                    >
                        <img
                            src="@/assets/logo_template.svg"
                            alt="topupme"
                        >
                    </router-link>
                    <router-link
                        v-else
                        to="'/steam/#form_pay'"
                        @click="showFormPay"
                    >
                        <img
                            src="@/assets/logo_template.svg"
                            alt="topupme"
                        >
                    </router-link>
                </div>
                <template-navigation />
            </div>
            <div class="template-content-wrap">
                <slot />
            </div>

            <div
                v-if="!isMainPage"
                class="template-footer-wrap"
            >
                <div class="template-footer-content-top">
                    <div class="template-footer-logo">
                        <router-link
                            to="'/steam/#form_pay'"
                            @click="showFormPay"
                        >
                            <img
                                src="@/assets/logo_template.svg"
                                alt="topupme"
                            >
                        </router-link>
                    </div>
                    <template-navigation />
                </div>


                <div class="template-footer-content-center">
                    <div class="template-footer-contact">
                        <h3>Контакты:</h3>

                        <p>
                            ТОО «Квиктум», 201140008964<br>
                            050012, Казахстан,<br>
                            город Алматы, Алмалинский район, ул. Мауленова, д. 96/77
                        </p>
                        <p>
                            <a href="mailto:info@quicktum.kz">info@quicktum.kz</a>
                        </p>
                    </div>
                    <div class="template-footer-logo-qt">
                        <a
                            target="_blank"
                            href="https://quicktum.kz/"
                        >
                            <img
                                src="@/assets/logo_qt.svg"
                                alt="quicktum"
                            >
                        </a>
                    </div>
                </div>

                <div class="template-footer-content-bottom">
                    <a
                        target="_blank"
                        href="../../download/Положение_конфиденциальности_и_обработки_персональных_данных_1.pdf"
                    >
                        Политика конфиденциальности
                    </a>
                    <a
                        target="_blank"
                        href="../../download/Публичная_оферта_для_плательщика_1_1.pdf"
                    >
                        Пользовательское соглашение
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useGlobalStore } from '@/stores/global'
import TemplateNavigation from "@/components/TemplateNavigation.vue";
export default defineComponent({
  components: {
    TemplateNavigation
  },
  setup () {
    const globalStore = useGlobalStore();
    return {
      showFormPay: globalStore.showFormPay
    };
  },
  computed: {
    isMainPage() {
      return !this.$route.path.replaceAll('/', '');
    }
  }
});
</script>

<style lang="less">
  .template-bg {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background-color: #242527;
    min-height: 100vh;

    &.template-main-bg {
      background-color: #131313;

      &:before {
        background-image: url("@/assets/top_main_bg.svg");
        background-size: 100%;
        background-repeat: no-repeat;
        position: fixed;
        content: '';
        right: 0;
        top: 0;
        width: 540px;
        height: 660px;
      }

      &:after {
        position: fixed;
        content:'';
        left: 0;
        bottom: 0;
        background-image: radial-gradient(farthest-side at 30% 150%, #E88102, rgba(232, 129, 2, 0.9), rgba(232, 129, 2, 0.3), rgba(232, 129, 2, 0));
        width: 80%;
        height: 80%;
      }
    }

    &:before {
      background-image: url("@/assets/top_line_bg.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      position: fixed;
      content: '';
      right: 0;
      top: 0;
      width: 596px;
      height: 624px;
    }

    &:after {
      background-image: url("@/assets/steam_logo_bg.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      position: fixed;
      content: '';
      left: 0;
      bottom: 0;
      width: 434px;
      height: 486px;
    }
  }

  .template-wrap {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .template-content-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%
  }

  .template-header-wrap, .template-footer-wrap {
    position: relative;
    max-width: 2000px;
    margin: 0 auto;
    padding: 0 110px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
  }

  .template-header-wrap {
    top: 0;
  }

  .template-header-logo {
    padding: 24px 0;

    img {
      display: block;
    }
  }

  .template-footer-wrap {
    margin-bottom: 80px;
    border-radius: 16px;
    padding: 48px;
    width: calc(100% - 48px);
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(19, 19, 19, 1);
  }

  .template-footer-content-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .template-footer-logo {
      display: flex;
      justify-content: center;
    }

    img {
      display: flex;
    }
  }

  .template-footer-content-center {
    display: flex;
    padding: 24px 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      display: block;
    }

    h3 {
      font-size: 20px;
      margin: 8px 0 0;
    }
    p {
      margin: 8px 0 0;
      color: rgba(161, 161, 161, 1);
    }

    a {
      text-decoration: underline;
    }
  }

  .template-footer-content-bottom {
    text-align: center;
    border-top: 1px solid rgba(161, 161, 161, 0.2);
    padding-top: 24px;
    a {
      font-size: 16px;
      color: rgba(214, 213, 214, 1);
      text-decoration: none;
      margin: 0 34px;
    }
  }

  .home-content {
    margin-top: -100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  @media (max-width: 1100px) {
    .home-content {
      margin-top: -66px;
    }

    .template-bg {
      &.template-main-bg {
        &:before {
          width: 220px;
          height: 330px;
        }
        &:after {
          background-image: radial-gradient(farthest-side at 10% 150%, #E88102, rgba(232, 129, 2, 0.9), rgba(232, 129, 2, 0.3), rgba(232, 129, 2, 0));
          width: 120%;
          height: 50%;
        }
      }

      &:before {
        width: 298px;
        height: 312px;
      }

      &:after {
        width: 217px;
        height: 243px;
      }
    }

    .template-header-logo {
      padding: 20px 0;

      img {
        //width: 160px;
      }
    }

    .template-header-wrap, .template-footer-wrap {
      padding: 0 20px;
    }

    .template-header-wrap {
      .template-navigation-icon-mobile {
        display: block;
      }
      .template-navigation-link {
        display: none;
      }
    }

    .template-footer-wrap {
      margin-bottom: 50px;
      padding: 20px;
      width: calc(100% - 40px);

      .template-navigation {
        width: 100%;
      }

      .template-navigation-link {
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin: 24px 0 0;

        a {
          margin: 5px 0;
        }
      }
    }

    .template-footer-content-top {
      flex-direction: column;
    }

    .template-footer-content-center {
      padding: 16px 0;
      flex-direction: column;
      align-items: center;

      .template-footer-contact {
        width: 100%;
        text-align: center;

        h3 {
          font-size: 16px;
        }
        p {
          font-size: 12px;
        }
      }

      .template-footer-logo-qt {
        margin-top: 24px;
      }
    }

    .template-footer-content-bottom {
      padding-top: 16px;

      a {
        display: block;
        margin: 5px 0;
      }
    }
  }
</style>
