<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <img
                class="form-icon-success"
                src="@/assets/icon_popup_success.svg"
                alt=""
            >
            <div class="form-text-status-title">
                Пополнение успешно!
            </div>
            <div class="form-text-status-description">
                Логин Steam {{ store.currentTopUp.account }}<br>
                Сумма - {{ store.currentTopUp.amountUsd }} USD<br>
                {{ formatTime(store.currentTopUp.processedDate) }}
            </div>
            <button class="form-button-submit">
                <router-link
                    to="/steam/#form_pay"
                    @click="showFormPay"
                >
                    Спасибо
                </router-link>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useClientStore} from '@/stores/client';
import {useGlobalStore} from '@/stores/global';
import dayjs from 'dayjs';

export default defineComponent({
  setup() {
    const store = useClientStore();
    const globalStore = useGlobalStore();
    return {
      store,
      showFormPay: globalStore.showFormPay
    };
  },
  methods: {
    formatTime(time: number) {
      const date = dayjs(time * 1000);

      return date.format('HH:mm DD.MM.YYYY');
    }
  }
});
</script>

<style lang="less">
.form-icon-success {
  width: 120px;
  margin: 0 auto;
}
</style>
