import {defineStore} from "pinia";

export const useGlobalStore = defineStore({
    id: "globalStore",
    state: (): any =>
        ({
            showForm: {
                pay: true,
                payFinish: false,
                login: false,
                registration: false,
                restoreEmail: false,
                restorePassword: false,
                support: false
            },
            showPopup: {
                successPay: false,
                checkPay: false,
                successSupport: false,
                successRestoreEmail: false,
                successRegistration: false,
                errorRequest: false,
                errorPay: false,
                errorSessionPay: false,
                loadingPay: false
            },
            errorMessage: ''
        }),
    actions: {
        hideAllForm() {
            for (const key in this.showForm) {
                this.showForm[key] = false
            }
        },
        hideAllPopup() {
            for (const key in this.showPopup) {
                this.showPopup[key] = false
            }
        },
        hideAll() {
            this.hideAllPopup();
            this.hideAllForm();
        },
        showSuccessPopup() {
            this.hideAll();
            this.showPopup.successPay = true;
        },
        showSuccessSupportPopup() {
            this.hideAll();
            this.showPopup.successSupport = true;
        },
        showErrorPayPopup() {
            this.hideAll();
            this.showPopup.errorPay = true;
        },
        showErrorSessionPayPopup() {
            this.hideAll();
            this.showPopup.errorSessionPay = true;
        },
        showErrorRequestPopup() {
            this.hideAll();
            this.showPopup.errorRequest = true;
        },
        showLoadingPopup() {
            this.hideAll();
            this.showPopup.loadingPay = true;
        },
        showSuccessRestoreEmailPopup() {
            this.hideAll();
            this.showPopup.successRestoreEmail = true;
        },
        showSuccessRegistrationPopup() {
            this.hideAll();
            this.showPopup.successRegistration = true;
        },
        showPayCheck() {
            this.hideAll();
            this.showPopup.checkPay = true;
        },
        showFormPay() {
            this.hideAll();
            this.showForm.pay = true;
        },
        showFormPayFinish() {
            this.hideAll();
            this.showForm.payFinish = true;
        },
        showFormLogin() {
            this.hideAll();
            this.showForm.login = true;
        },
        showFormRegistration() {
            this.hideAll();
            this.showForm.registration = true;
        },
        showFormRestoreEmail() {
            this.hideAll();
            this.showForm.restoreEmail = true;
        },
        showFormRestorePassword() {
            this.hideAll();
            this.showForm.restorePassword = true;
        },
        showFormSupport() {
            this.hideAll();
            this.showForm.support = true;
        }
    }
});
