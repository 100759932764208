<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <span
                class="form-icon-loader icon-loader"
            />
            <div class="form-text-status-title">
                Конвертируем валюты ...
            </div>
            <div class="form-text-status-description">
                Это займет несколько секунд.
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {useClientStore} from "@/stores/client";

export default defineComponent({
  setup () {
    const store = useClientStore();

    return {
      store
    };
  },
  methods: {
  }
});
</script>

<style lang="less">
.form-icon-error {
  width: 120px;
  margin: 0 auto;
}
</style>
