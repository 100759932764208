<template>
    <div class="home-content">
        <form-restore-password v-if="globalStore.showFord.successPay" />
        <popup-error v-else-if="globalStore.showPopup.errorRequest" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {useGlobalStore} from "@/stores/global";

import PopupError from "@/components/popup/PopupError.vue";
import FormRestorePassword from "@/components/form/FormRestorePassword.vue";
import { useSupplierStore } from "@/stores/supplier";

export default defineComponent({
  components: {
    FormRestorePassword,
    PopupError
  },
  setup () {
    const globalStore = useGlobalStore();
    const supplierStore = useSupplierStore();
    return {
      supplierStore,
      globalStore
    };
  },
  async created() {
    if(this.$route.params.id && this.$route.params.id !== '') {
      this.globalStore.showFormRestorePassword()
    }
  }

});
</script>

<style lang="less">

</style>
