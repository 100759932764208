<template>
    <div
        id="form_registration"
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Регистрация исполнителя (Казахстан)
            </h2>
            <div class="form-error-message">
                {{ errorMessage ? `${errorMessage} Повторите отправку` : '' }}
            </div>

            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Новый пароль
                    </div>
                    <input
                        v-model="password"
                        :class="{'shake' : passwordAnimation}"
                        type="text"
                        placeholder="qwerty12"
                    >
                </div>
                <div class="form-field-help">
                    <div class="form-field-help-title">
                        Введите пароль
                    </div>
                    8 и более символов и как минимум:
                    <ul>
                        <li>одна заглавная и одна строчная буква</li>
                        <li>одна цифра</li>
                        <li>один специальный символ, например: ~!?@#$%^&*</li>
                    </ul>
                </div>
                <div class="form-field-icon icon-help" />
            </div>

            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Повторите новый пароль
                    </div>
                    <input
                        v-model="confirmPassword"
                        :class="{'shake' : confirmPasswordAnimation}"
                        type="text"
                        placeholder="qwerty12"
                    >
                </div>
                <div class="form-field-help">
                    <div class="form-field-help-title">
                        Введите пароль
                    </div>
                    8 и более символов и как минимум:
                    <ul>
                        <li>одна заглавная и одна строчная буква</li>
                        <li>одна цифра</li>
                        <li>один специальный символ, например: ~!?@#$%^&*</li>
                    </ul>
                </div>
                <div class="form-field-icon icon-help" />
            </div>

            <button
                class="form-button-submit"
                @click="handleSuccess"
            >
                Восстановить пароль
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {useGlobalStore } from "@/stores/global";
import { useSupplierStore } from "@/stores/supplier";

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();
    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      errorMessage: '',
      passwordAnimation: false,
      confirmPasswordAnimation: false,
      password: '',
      confirmPassword: ''
    }
  },
  methods: {
    async handleSuccess() {
      this.passwordAnimation = false;
      this.confirmPasswordAnimation = false;
      this.errorMessage = '';

      await new Promise(res => setTimeout(res, 100));

      const {password} = this;

      // if(!/^\S+@\S+\.\S+$/.test(email)) {
      //   this.emailAnimation = true;
      // } else {
      //   const {success, errorMessage} = await this.supplierStore.registration({email})
      //
      //   if(success) {
      //     this.email = '';
      //     this.errorMessage = '';
      //   } else {
      //     this.errorMessage = errorMessage;
      //   }
      // }
    }
  }
});
</script>

<style lang="less">

</style>
