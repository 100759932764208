<template>
    <div
        id="form_pay_finish"
        class="form-wrap"
    >
        <div

            class="form-content"
        >
            <h2>
                Пополнить Steam
            </h2>
            <div class="form-error-message">
                {{ store.checkData.errorMessage }}
            </div>
            <div class="form-pay-info-row">
                <span>Логин Steam</span>
                <span>{{ store.checkData.steamAccount }}</span>
            </div>
            <div class="form-pay-info-row">
                <span>Сумма, USD</span>
                <span>{{ store.checkData.usdAmount }} $</span>
            </div>
            <div class="form-pay-info-row">
                <span>Сумма, РУБ</span>
                <span class="price">{{ store.checkData.rubAmount }} P</span>
            </div>
            <button
                class="form-button-submit"
                @click="topUpCheckAndCreate"
            >
                {{ `Перевести ${store.checkData.rubAmount}р` }}
                <div
                    v-if="store.checkData.success"
                    class="form-field-help"
                >
                    <div class="form-field-help-title">
                        Подтвердите перевод
                    </div>
                    Максимальное время обработки заявки - {{ store.session.timeToTopup }} минут. Если деньги не придут за указанное время, обратитесь в техподдержку.
                </div>
            </button>
        </div>
    </div>
</template>

<script lang="ts">

import {useGlobalStore} from "@/stores/global";
import {defineComponent} from "vue";
import {useClientStore} from "@/stores/client";

export default defineComponent({
  setup () {
    const store = useClientStore();
    const globalStore = useGlobalStore();
    //store.getStartSession();
    return {
      store,
      globalStore
    };
  },
  methods: {
    async topUpCheckAndCreate() {
      await this.store.topUpCreate()

      if(this.store.currentTopUp.status === "FAILED") {
        this.globalStore.showErrorSessionPayPopup()
      } else {
        window.open(this.store.currentTopUp.paymentUrl)
        this.globalStore.showFormPay()
      }
      this.store.clearTopUpCheck()
    }
  }
});
</script>

<style lang="less">
  .form-pay-info-row {
    padding: 10px;
    color: #ACACAC;
    display: flex;
    justify-content: space-between;

    .price {
      color: #E88102;
    }
  }

</style>
