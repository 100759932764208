import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_restore_password = _resolveComponent("form-restore-password")!
  const _component_popup_error = _resolveComponent("popup-error")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.globalStore.showFord.successPay)
      ? (_openBlock(), _createBlock(_component_form_restore_password, { key: 0 }))
      : (_ctx.globalStore.showPopup.errorRequest)
        ? (_openBlock(), _createBlock(_component_popup_error, { key: 1 }))
        : _createCommentVNode("", true)
  ]))
}