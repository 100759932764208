<template>
    <div
        id="form_restore_email"
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Регистрация исполнителя (Казахстан)
            </h2>
            <div class="form-error-message">
                {{ errorMessage }}
            </div>

            <div class="form-field">
                <div class="form-field-content">
                    <div class="form-field-label">
                        Email
                    </div>
                    <input
                        v-model="email"
                        :class="{'shake' : emailAnimation}"
                        type="text"
                        placeholder="example@email.com"
                    >
                </div>
            </div>

            <button
                class="form-button-submit"
                @click="handleSuccess"
            >
                Восстановить пароль
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {useGlobalStore } from "@/stores/global";
import { useSupplierStore } from "@/stores/supplier";

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();
    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      errorMessage: '',
      emailAnimation: false,
      email: ''
    }
  },
  methods: {
    async handleSuccess() {
      this.emailAnimation = false;
      this.errorMessage = '';

      await new Promise(res => setTimeout(res, 100));

      const {email} = this;

      if(!/^\S+@\S+\.\S+$/.test(email)) {
        this.emailAnimation = true;
      } else {
        const {success, errorMessage} = await this.supplierStore.restore({email})

        if(success) {
          this.email = '';
          this.errorMessage = '';
          this.globalStore.showSuccessRestoreEmailPopup()
        } else {
          this.errorMessage = errorMessage;
        }
      }
    }
  }
});
</script>

<style lang="less">

</style>
