<template>
    <div class="home-content">
        <supplier-tables />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useGlobalStore } from "@/stores/global";

import SupplierTables from "@/components/supplier/SupplierTables.vue";

export default defineComponent({
  components: {
    SupplierTables
  },
  setup () {
    const globalStore = useGlobalStore();
    return {
      globalStore
    };
  }
});
</script>
