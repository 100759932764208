<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <img
                class="form-icon-error"
                src="@/assets/icon_popup_error.svg"
                alt=""
            >
            <div class="form-text-status-title">
                Упссс ...
            </div>
            <div class="form-text-status-description">
                {{ store.currentTopUp.errorDescription || 'Платеж не проходит' }}<br>
                Возвращаем оплату на вас счет.<br><br>
                Логин Steam {{ store.currentTopUp.account }}<br>
                Сумма - {{ store.currentTopUp.amountUsd || 0 }} USD<br>
                {{ formatTime(store.currentTopUp.processedDate) }}
            </div>
            <button class="form-button-submit">
                <router-link
                    to="/steam/#form_pay"
                    @click="showFormPay"
                >
                    На главную
                </router-link>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {useGlobalStore} from "@/stores/global";
import {useClientStore} from "@/stores/client";

import dayjs from "dayjs";
export default defineComponent({
  setup () {
    const store = useClientStore();
    const globalStore = useGlobalStore();
    return {
      store,
      showFormPay: globalStore.showFormPay
    };
  },
  methods: {
    formatTime(time: number) {
      const date = dayjs(time * 1000);

      return date.format('HH:mm DD.MM.YYYY');
    }
  }
});
</script>

<style lang="less">
.form-icon-error {
  width: 120px;
  margin: 0 auto;
}
</style>
